import React from "react"
import tw from "twin.macro"
import PropTypes from "prop-types"

const Section = ({
  children,
  bgColour,
  id,
  largePadding,
  progressOffset,
  noHero,
  addedStyles,
  ...rest
}) => {
  return (
    <section
      id={id}
      css={[
        tw`relative py-7 overflow-hidden lg:scroll-mt-20 xl:py-10`,
        largePadding && tw`py-9 xl:py-16`,
        noHero && tw`pb-12 pt-16 md:pt-20 lg:pt-[134px]`,
        bgColour,
        progressOffset && tw`scroll-mt-16 md:scroll-mt-32`,
        addedStyles,
      ]}
      {...rest}
    >
      {children}
    </section>
  )
}

export default Section

Section.propTypes = {
  bgColour: PropTypes.object,
  id: PropTypes.string,
  largePadding: PropTypes.bool,
  progressOffset: PropTypes.bool,
  noHero: PropTypes.bool,
  addedStyles: PropTypes.object,
}
Section.defaultProps = {
  bgColour: {},
  id: "",
  largePadding: false,
  progressOffset: false,
  noHero: false,
  addedStyles: {},
}
